
import Loader from '@/components/Loader.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';
import { mapState } from 'vuex';
import OnboardingLayout from '../../../../layouts/OnboardingLayout.vue';

import { vatStatuses } from '@/config/constants';

import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import { capitalize, cloneDeep } from 'lodash';

import SubmitButton from '@/components/SubmitButton.vue';
import { sendGetAllProfessionalactivitiesRequest } from '@/services/professionalactivitiesService';
import { getSchools } from '@/services/schools';
import PickTier from '@/views/registration/completeUserRegistration/components/PickTier.vue';
import CompleteUserRegistrationWithoutCompanyNumberForm from '../forms/completeUserRegistrationWithoutCompanyNumberForm.vue';

import StepperHeader from '@/components/stepper/StepperHeader.vue';
import { DeepNullable, MapStateToComputed } from '@/helpers/types';
import { analyticsIdentify, analyticsTrack } from '@/services/analytics';
import { sendCompleteUserRegistrationWithoutCompany } from '@/services/auth';
import { sendGetAllSocialsecretariatsRequest } from '@/services/socialsecretariat';
import { InitializedUseraccountNotCompletedRegistrationAuthState } from '@/store/modules/auth';
import dayjs from 'dayjs';
import {
    IProfessionalactivityViewModel,
    ISchoolViewModel,
    ISocialsecretariatViewModel,
    completeRegistrationWithoutCompanyData,
    BILLING_PLAN_TYPE
} from 'dexxter-types';
import mixins from 'vue-typed-mixins';
import { createCompleteRegistrationWithoutCompanySubmitData } from '../models/completeRegistrationWithoutCompany';

export default mixins(formRulesMixin, lookupCompanynumberMixin).extend({
    components: {
        Loader,
        OnboardingLayout,
        ConfirmationDialog,
        SubmitButton,
        StepperHeader,
        PickTier,
        CompleteUserRegistrationWithoutCompanyNumberForm
    },

    mixins: [formRulesMixin, lookupCompanynumberMixin],

    data() {
        return {
            formValid: false as boolean,
            schools: [] as ISchoolViewModel[],
            socialSecretariats: null as null | ISocialsecretariatViewModel[],
            professionalactivities: null as IProfessionalactivityViewModel[] | null,
            registrationData:
                createCompleteRegistrationWithoutCompanySubmitData() as DeepNullable<completeRegistrationWithoutCompanyData>,
            completeRegistrationStep: 1 as number,
            submitDialog: false as boolean,
            loading: {
                fetchSocialsecretariat: false,
                handleCompleteRegistration: false
            } as {
                fetchSocialsecretariat: boolean;
                handleCompleteRegistration: boolean;
            },
            registrationSteps: [
                { i18nPath: 'completeUserRegistration.chooseTier' },
                { i18nPath: 'completeUserRegistration.vatInformation' }
            ]
        };
    },

    computed: {
        ...(mapState('auth', [
            'currentUserData'
        ]) as MapStateToComputed<InitializedUseraccountNotCompletedRegistrationAuthState>),

        vatLiableConfirmDialogText(): string {
            const baseText = this.$i18n.t('completeUserRegistration.confirm_base_text');

            if (this.registrationData.vatLiable === vatStatuses.VATLIABLE) {
                return `${baseText}${this.$i18n.t('completeUserRegistration.confirm_vatLiable')}`;
            } else if (this.registrationData.vatLiable === vatStatuses.VATEXEMPT) {
                return `${baseText}${this.$i18n.t('completeUserRegistration.confirm_exempt_vatLiable')}`;
            } else if (this.registrationData.vatLiable === vatStatuses.NOTVATLIABLE) {
                return `${baseText}${this.$i18n.t('completeUserRegistration.confirm_not_vatLiable')}`;
            }

            return '';
        },

        isLoading(): boolean {
            return this.loading.fetchSocialsecretariat || this.loading.handleCompleteRegistration;
        }
    },

    created() {
        if (
            (this.currentUserData.userinformation as any).roles &&
            (this.currentUserData.userinformation as any).roles.length !== 0
        ) {
            this.$router.push({ name: 'dashboard' });
            return;
        }

        this.fetchData();
    },

    methods: {
        navigateBack() {
            this.completeRegistrationStep -= 1;
        },
        selectTier(selectedUserType: 'main' | 'secondary' | 'student'): void {
            this.registrationData.subscriptionType = 'pro_tier';

            if (selectedUserType === 'main') {
                this.registrationData.role = 'main';
            } else if (selectedUserType === 'secondary') {
                this.registrationData.role = 'secondary';
            } else if (selectedUserType === 'student') {
                this.registrationData.role = 'student';
            }

            this.completeRegistrationStep += 1;
        },
        async fetchData(): Promise<void> {
            this.loading.fetchSocialsecretariat = true;
            await Promise.all([
                sendGetAllSocialsecretariatsRequest()
                    .then((data) => {
                        this.socialSecretariats = data
                            .map((ss) => {
                                return {
                                    ...ss,
                                    name: capitalize(ss.name)
                                };
                            })
                            .sort(this.sortByNameAsc);
                    })
                    .finally(() => {
                        this.loading.fetchSocialsecretariat = false;
                    }),
                sendGetAllProfessionalactivitiesRequest(this.currentUserData.userinformation.language).then(
                    (result) => {
                        this.professionalactivities = result;
                    }
                ),
                getSchools().then((result) => {
                    this.schools = result;
                })
            ]);
        },
        async handleSubmit(): Promise<void> {
            try {
                this.loading.handleCompleteRegistration = true;
                await sendCompleteUserRegistrationWithoutCompany(cloneDeep(this.registrationData));

                // Refetch currentUserData & permissions
                const [currentUserData] = await Promise.all([
                    this.$store.dispatch('auth/getCurrentUser'),
                    this.$store.dispatch('auth/getPermissions')
                ]);

                notify.call(this, {
                    title: this.$t('completeUserRegistration.succesful_completed'),
                    text: this.$t('completeUserRegistration.succesful_completed')
                });

                const eventProps = {
                    Promo_Code: this.registrationData.promocode,
                    Mode: 'zonder ondernemingsnummer',
                    Plan_Type: 'trial' as BILLING_PLAN_TYPE | 'trial',
                    User_Type: this.registrationData.role,
                    Vat_Type: this.registrationData.vatLiable,
                    Social_Insurance_Fund: this.socialSecretariats
                        ?.find((_ss) => _ss.id === this.registrationData.socialsecretariatId)
                        ?.name?.toLowerCase(),
                    Enterprise_Postal_Code: this.registrationData.address.zipcode?.toString()
                };
                const userProps = {
                    Trial_Expires_At: dayjs().add(30, 'days').toISOString(),
                    Promo_Code: this.registrationData.promocode,
                    Birth_Date: this.registrationData.birthDate,
                    Enterprise_Street_Name: this.registrationData.address.street,
                    Enterprise_House_Number: this.registrationData.address.number,
                    Enterprise_Postal_Code: this.registrationData.address.zipcode?.toString(),
                    Enterprise_City: this.registrationData.address.city,
                    Knows_Us_From: this.registrationData.knowsUsFrom,
                    Plan_Type: 'trial' as const,
                    Social_Insurance_Fund: this.socialSecretariats
                        ?.find((_ss) => _ss.id === this.registrationData.socialsecretariatId)
                        ?.name?.toLowerCase(),
                    Vat_Type: this.registrationData.vatLiable,
                    Completed_Registration: true
                };

                analyticsIdentify(this.currentUserData.id, userProps);
                analyticsTrack('User Completes Registration', eventProps);

                this.$gtmTrackEvent({
                    event: 'completed-user-registration',
                    userId: currentUserData.id,
                    email: currentUserData.userinformation.email,
                    firstName: currentUserData.userinformation.firstName,
                    lastName: currentUserData.userinformation.name
                });

                this.$router.replace({ name: 'dashboard' });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading.handleCompleteRegistration = false;
            }
        },
        sortByNameAsc(a: ISocialsecretariatViewModel, b: ISocialsecretariatViewModel): number {
            return a.name >= b.name ? 1 : -1;
        }
    }
});
